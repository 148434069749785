.dataTables_filter {
  float: right; }

.dataTables_filter input {
  margin: 0px 5px 0px 5px; }

.dataTables_paginate {
  float: right; }

.dataTables_paginate .pagination {
  margin: 0px; }
