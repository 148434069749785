.Footer {
  text-align: center;
  background-color: #F6F9FA;
  border-top: 1px solid #E9F0F3;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #888888;
  font-weight: 300; }

.Footer-text {
  padding-top: 10px;
  opacity: 0.8;
  color: #888888; }

.Footer-link {
  text-decoration: none;
  color: #888888 !important;
  font-weight: 400; }

.Footer-link:hover {
  text-decoration: underline; }

.Footer-logo {
  width: 49px;
  height: 46px; }
