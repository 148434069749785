.skin-yellow-light .main-header .logo {
  background-color: #FDF4EB !important; }


md-toast[md-theme=warn] .md-toast-content {
  background-color: #F44336; }

[ui-view].ng-enter,
[ui-view].ng-leave {
  transition: opacity ease-in-out 200ms; }

[ui-view].ng-enter,
[ui-view].ng-leave.ng-leave-active {
  opacity: 0; }

[ui-view].ng-enter.ng-enter-active {
  opacity: 1; }

.Page.ng-leave.ng-leave-active {
  display: none; }

.u-center {
  text-align: center; }







@media screen and (max-width: 1024px) {
  #overview-table {
    max-width: 600px;
    margin: 0 auto; }
  #overview-table th, td {
    white-space: nowrap; }
  #overview-table_wrapper {
    overflow: scroll; } }

.ForgotPassword-input {
  margin-bottom: 0; }



.tab-content {
  margin-top: 50px; }

.formly-field-repeatSection button {
  margin-left: 15px !important; }

.btn-github:hover {
  color: #fff; }

.btn-facebook:hover {
  color: #fff; }

.btn-google:hover {
  color: #fff; }



@media screen and (max-width: 1024px) {
  #overview-table {
    max-width: 600px;
    margin: 0 auto; }
  #overview-table th, td {
    white-space: nowrap; }
  #overview-table_wrapper {
    overflow: scroll; } }

.custom_selectfield {
  width: 100%; }


.dataTables_wrapper {
  overflow-x: auto; }

.RegisterForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 80%;
  min-width: 300px; }

.RegisterForm-submit {
  margin-bottom: 40px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0; }

.ResetPassword-input {
  margin-bottom: 0; }



.color-palette {
  height: 35px;
  line-height: 35px;
  text-align: center; }

.color-palette-set {
  margin-bottom: 15px; }

.color-palette span {
  display: none;
  font-size: 12px; }

.color-palette:hover span {
  display: block; }

.color-palette-box h4 {
  position: absolute;
  top: 100%;
  left: 25px;
  margin-top: -40px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  display: block;
  z-index: 7; }

.bs-glyphicons {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden; }
  .bs-glyphicons li {
    float: left;
    width: 25%;
    height: 115px;
    padding: 10px;
    margin: 0 -1px -1px 0;
    font-size: 12px;
    line-height: 1.4;
    text-align: center;
    border: 1px solid #ddd; }
  .bs-glyphicons .glyphicon {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 24px; }
  .bs-glyphicons .glyphicon-class {
    display: block;
    text-align: center;
    word-wrap: break-word;
    /* Help out IE10+ with class names */ }
  .bs-glyphicons li:hover {
    background-color: rgba(86, 61, 124, 0.1); }

@media (min-width: 768px) {
  .bs-glyphicons li {
    width: 12.5%; } }















.Footer {
  text-align: center;
  background-color: #F6F9FA;
  border-top: 1px solid #E9F0F3;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #888888;
  font-weight: 300; }

.Footer-text {
  padding-top: 10px;
  opacity: 0.8;
  color: #888888; }

.Footer-link {
  text-decoration: none;
  color: #888888 !important;
  font-weight: 400; }

.Footer-link:hover {
  text-decoration: underline; }

.Footer-logo {
  width: 49px;
  height: 46px; }

.ForgotPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }


.Landing {
  height: 100%; }

.Landing-cover {
  background-image: url("/img/cover.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  height: 700px; }

.Landing-heading,
.Landing-subtitle {
  color: white; }

.Landing-headingLight {
  font-weight: 300; }

.Landing-heading {
  color: white;
  letter-spacing: -3px;
  margin-top: 20px;
  font-size: 60px;
  text-align: center; }

.Landing-subtitle {
  opacity: 0.7;
  text-align: center; }

.Landing-getStarted {
  padding: 12px 30px;
  font-weight: bold;
  color: #535362 !important;
  border-radius: 4px;
  text-transform: box-shadow 250ms; }

.Landing-getStarted:hover {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important; }

.Landing-laravelAngular {
  position: relative; }

.Landing-ampersand {
  font-size: 50px;
  color: #00A4C6;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 300; }

.Landing-angular {
  background-color: #F6F9FA;
  border-left: 1px solid #E9F0F3; }

.Landing-laravel,
.Landing-angular {
  text-align: center;
  color: #21455B;
  border-bottom: 1px solid #E9F0F3; }

.Landing-laravelAngular-title {
  padding-top: 30px;
  margin-bottom: 15px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #21455B; }

.Landing-laravelAngular-subtitle {
  color: #535362;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 300; }

.DemoCode {
  font-family: "Lucida Console", Monaco, monospace;
  overflow: auto !important;
  padding: 10px;
  line-height: 1.428571429;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 80%;
  margin: 30px auto 100px;
  text-align: left;
  padding-left: 20px;
  font-size: 14px; }

.DemoCode-highlight {
  color: #DD4A68; }

.DemoCode-comment {
  color: #888888; }

.DemoCode-operator {
  color: #888888; }

.DemoCode-string {
  color: #690; }

.DemoCode-secondary {
  color: #07A; }

.Landing-features {
  text-align: center;
  margin: 60px 10px; }

.Landing-featuresMainTitle {
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 15px;
  color: #21455B; }

.Landing-featuresMainDescription {
  width: 70%;
  margin: 0 auto 30px;
  color: #535362;
  font-weight: 300; }

.Landing-featuresEntry {
  width: 176px;
  height: 176px;
  border-radius: 50%;
  margin: 40px auto 20px; }

.Landing-featuresEntry-icon {
  height: 100%; }

.Landing-featuresTitle {
  color: #21455B;
  letter-spacing: -1px;
  margin-top: 40px; }

.Landing-featuresDescription {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 300;
  color: #535362; }

.Landing-featuresEntry--restful {
  background-color: #00A4C6; }

.Landing-featuresEntry--jwt {
  background-color: #EF704D; }

.Landing-featuresEntry--generators {
  background-color: #25C0CC; }

.Landing-featuresEntry--restful md-icon {
  width: 100px; }

.Landing-featuresEntry--jwt md-icon {
  width: 80px; }

.Landing-featuresEntry--generators md-icon {
  width: 80px; }

/*
This is a terrible temporary hack,
to fix layout issues with flex on iOS (chrome & safari)
Make sure to remove this when you modify this demo
*/
@media only screen and (max-width: 950px) {
  .iOS-hack .flex-100,
  .iOS-hack .flex-50,
  .iOS-hack .flex-33 {
    -ms-flex: none !important;
    flex: none !important; } }

.dataTables_filter {
  float: right; }

.dataTables_filter input {
  margin: 0px 5px 0px 5px; }

.dataTables_paginate {
  float: right; }

.dataTables_paginate .pagination {
  margin: 0px; }

.Login-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }


.Register-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.ResetPassword-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

.Login-formContainer {
  margin-top: 80px;
  margin-bottom: 80px; }

/*# sourceMappingURL=app.css.map */
