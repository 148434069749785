.Landing {
  height: 100%; }

.Landing-cover {
  background-image: url("/img/cover.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  height: 700px; }

.Landing-heading,
.Landing-subtitle {
  color: white; }

.Landing-headingLight {
  font-weight: 300; }

.Landing-heading {
  color: white;
  letter-spacing: -3px;
  margin-top: 20px;
  font-size: 60px;
  text-align: center; }

.Landing-subtitle {
  opacity: 0.7;
  text-align: center; }

.Landing-getStarted {
  padding: 12px 30px;
  font-weight: bold;
  color: #535362 !important;
  border-radius: 4px;
  text-transform: box-shadow 250ms; }

.Landing-getStarted:hover {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important; }

.Landing-laravelAngular {
  position: relative; }

.Landing-ampersand {
  font-size: 50px;
  color: #00A4C6;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 300; }

.Landing-angular {
  background-color: #F6F9FA;
  border-left: 1px solid #E9F0F3; }

.Landing-laravel,
.Landing-angular {
  text-align: center;
  color: #21455B;
  border-bottom: 1px solid #E9F0F3; }

.Landing-laravelAngular-title {
  padding-top: 30px;
  margin-bottom: 15px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #21455B; }

.Landing-laravelAngular-subtitle {
  color: #535362;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 300; }

.DemoCode {
  font-family: "Lucida Console", Monaco, monospace;
  overflow: auto !important;
  padding: 10px;
  line-height: 1.428571429;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 80%;
  margin: 30px auto 100px;
  text-align: left;
  padding-left: 20px;
  font-size: 14px; }

.DemoCode-highlight {
  color: #DD4A68; }

.DemoCode-comment {
  color: #888888; }

.DemoCode-operator {
  color: #888888; }

.DemoCode-string {
  color: #690; }

.DemoCode-secondary {
  color: #07A; }

.Landing-features {
  text-align: center;
  margin: 60px 10px; }

.Landing-featuresMainTitle {
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 15px;
  color: #21455B; }

.Landing-featuresMainDescription {
  width: 70%;
  margin: 0 auto 30px;
  color: #535362;
  font-weight: 300; }

.Landing-featuresEntry {
  width: 176px;
  height: 176px;
  border-radius: 50%;
  margin: 40px auto 20px; }

.Landing-featuresEntry-icon {
  height: 100%; }

.Landing-featuresTitle {
  color: #21455B;
  letter-spacing: -1px;
  margin-top: 40px; }

.Landing-featuresDescription {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 300;
  color: #535362; }

.Landing-featuresEntry--restful {
  background-color: #00A4C6; }

.Landing-featuresEntry--jwt {
  background-color: #EF704D; }

.Landing-featuresEntry--generators {
  background-color: #25C0CC; }

.Landing-featuresEntry--restful md-icon {
  width: 100px; }

.Landing-featuresEntry--jwt md-icon {
  width: 80px; }

.Landing-featuresEntry--generators md-icon {
  width: 80px; }

/*
This is a terrible temporary hack,
to fix layout issues with flex on iOS (chrome & safari)
Make sure to remove this when you modify this demo
*/
@media only screen and (max-width: 950px) {
  .iOS-hack .flex-100,
  .iOS-hack .flex-50,
  .iOS-hack .flex-33 {
    flex: none !important; } }
